<template>
  <div class="row mb-3 align-items-center border p-3 rounded">
    <div class="col-12 col-md-8">
      <label :for="id" class="form-label">{{ label }}</label>
      <input 
        type="file" 
        @change="handleFileUpload" 
        class="form-control" 
        :id="id" 
        :required="required" 
        :accept="accept"
      >
      <small class="form-text text-muted">{{ fileName || noFileMessage }}</small>
    </div>
    <div class="col-12 col-md-4 mt-3 mt-md-0 d-flex justify-content-center align-items-center">
      <div class="preview-container">
        <div v-if="previewSrc" class="mb-2 mt-2 text-center">
          <img v-if="isImage" :src="previewSrc" alt="Preview" class="img-thumbnail" style="max-width: 100%; max-height: 200px;">
          
          <video v-else-if="isVideo" controls style="max-width: 100%; max-height: 200px;">
            <source :src="previewSrc" type="video/webm">
            Tu navegador no soporta la etiqueta de video.
          </video>
          
          <a v-else-if="isPdf" :href="previewSrc" target="_blank" class="text-white">{{ fileName }}</a>
          <span v-else class="text-white">{{ fileName }}</span>
        </div>
        <div v-else class="text-center">SIN ARCHIVO PARA MOSTRAR</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import Swal from 'sweetalert2';

export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    file: {
      type: [File, String],
      default: null,
    },
    preview: {
      type: String,
      default: '',
    },
    noFileMessage: {
      type: String,
      default: 'No se ha seleccionado ningún archivo',
    },
    accept: {
      type: String,
      default: '' 
    },
  },
  emits: ['file-change'],
  setup(props, { emit }) {
    const previewSrc = ref(props.preview || '');

    const isImage = ref(
      props.preview ? /\.(jpeg|jpg|gif|png)$/i.test(props.preview) : false
    );

    const isPdf = ref(
      props.preview ? /\.pdf$/i.test(props.preview) : false
    );

    const isVideo = ref(
      props.preview ? /\.webm$/i.test(props.preview) : false
    );

    watch(
      () => props.file,
      (newFile) => {
        if (newFile === null || newFile === undefined) {
          return;
        }

        if (newFile instanceof File) {
          const reader = new FileReader();
          reader.onload = (e) => {
            previewSrc.value = e.target.result;
            isImage.value = newFile.type.startsWith('image/');
            isPdf.value = newFile.type === 'application/pdf';
            isVideo.value = newFile.type === 'video/webm'; 
          };
          reader.readAsDataURL(newFile);
        } else if (typeof newFile === 'string') {
          previewSrc.value = newFile;
          isImage.value = /\.(jpeg|jpg|gif|png)$/i.test(newFile);
          isPdf.value = /\.pdf$/i.test(newFile);
          isVideo.value = /\.webm$/i.test(newFile); 
        } else {
          previewSrc.value = '';
          isImage.value = false;
          isPdf.value = false;
          isVideo.value = false;
        }
      },
      { immediate: true }
    );

    const fileName = computed(() => {
      if (props.file && typeof props.file !== 'string') {
        return props.file.name;
      } else if (typeof props.file === 'string') {
        return props.file.split('/').pop();
      }
      return '';
    });

    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        const allowedTypes = props.accept.split(',').map(type => type.trim());
        const isValidType = allowedTypes.some((type) => {
          if (type === 'image/*' && file.type.startsWith('image/')) {
            return true;
          }
          return file.type === type;
        });

        if (!isValidType) {
          Swal.fire({
            icon: 'error',
            title: 'Archivo no permitido',
            text: `Solo se permiten archivos de tipo ${props.accept}.`,
            confirmButtonText: 'Aceptar',
          });
          event.target.value = '';
          return;
        }

        emit('file-change', file);
        const reader = new FileReader();
        reader.onload = (e) => {
          previewSrc.value = e.target.result;
          isImage.value = file.type.startsWith('image/');
          isPdf.value = file.type === 'application/pdf';
          isVideo.value = file.type === 'video/webm'; 
        };
        reader.readAsDataURL(file);
      } else {
        previewSrc.value = '';
        isImage.value = false;
        isPdf.value = false;
        isVideo.value = false;
      }
    };

    return {
      previewSrc,
      handleFileUpload,
      fileName,
      isImage,
      isPdf,
      isVideo,
    };
  },
};
</script>

<style scoped>
.preview-container {
  border: 1px solid #ccc;
  min-height: 100px;
  margin: 5px;
  background-color: #305785;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
</style>
