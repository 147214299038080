import { createRouter, createWebHistory } from 'vue-router';
import MainLayout from '../layouts/MainLayout.vue';
import HomeView from '../views/HomeView.vue';
import PreciosView from '../views/precios/PreciosView.vue';
import LoginView from '../views/LoginView.vue';
import IndicesView from '../views/indices/IndicesView.vue';
import ContenidoView from '../views/contenidos/ContenidoView.vue';
import ViewTable from '../views/vistas/ViewActions.vue'

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/main',
    component: MainLayout,
    children: [
      {
        path: '',
        name: 'home',
        component: HomeView,
      },
      {
        path: 'novedades',
        name: 'Novedades',
        component: () => import(/* webpackChunkName: "novedades" */ '../views/novedades/NovedadesView.vue'),
      },
      {
        path: 'precios/:subMenu', 
        name: 'PreciosSubMenu',
        component: PreciosView,
      },
      {
        path: 'contenidos/:subMenu', 
        name: 'ContenidoView',
        component: ContenidoView,
      },
      {
        path: 'indices',
        name: 'Indices',
        component: IndicesView,
        props: { menu: 'indices' },
      },
      {
        path: 'vista', 
        name: 'Vista',
        component: ViewTable,
      },
      {
        path: 'administrador/:subMenu',
        name: 'AdministradorSubMenu',
        component: () => import(/* webpackChunkName: "administrador" */ '../views/administrador/AdministradorView.vue'),
        props: true, 
      },
      
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
