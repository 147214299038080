<template>
  <div
    class="modal fade"
    id="buttonLinkModal"
    tabindex="-1"
    aria-labelledby="buttonLinkModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="buttonLinkModalLabel">
            {{ isEditing ? "Editar Button" : "Nuevo Button" }}
          </h5>
          <button type="button" class="btn-close" @click="closeForm"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="mb-3">
              <label for="name" class="form-label">Nombre</label>
              <input
                type="text"
                v-model="form.name"
                class="form-control"
                id="name"
                required
              />
            </div>
            <div class="mb-3">
              <label for="title" class="form-label">Título</label>
              <input
                type="text"
                v-model="form.title"
                class="form-control"
                id="title"
              />
            </div>
            <div class="mb-3">
              <label for="content" class="form-label">Contenido</label>
              <textarea
                v-model="form.content"
                class="form-control"
                id="content"
              ></textarea>
            </div>
            <div class="mb-3">
              <label for="type-switch" class="form-label">¿Incluir botón?</label>
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :checked="form.type === 2"
                  @change="toggleButtonType"
                  id="type-switch"
                />
                <label class="form-check-label" for="type-switch">
                  {{ form.type === 2 ? "Sí" : "No" }}
                </label>
              </div>
            </div>
            <div class="mb-3 border p-3 rounded">
              <label for="type" class="form-label fw-bold"
                >Tipo de Enlace</label
              >
              <div class="input-group mb-3">
                <span class="input-group-text" id="select-addon">
                  <i class="bi bi-link-45deg"></i>
                </span>
                <select
                  v-model="form.linkType"
                  class="form-select shadow-sm"
                  id="type"
                  aria-describedby="select-addon"
                >
                  <option value="link">Enlace</option>
                  <option value="pdf">PDF</option>
                </select>
              </div>

              <div class="mb-3" v-if="form.linkType === 'link'">
                <label for="link" class="form-label">Enlace</label>
                <input
                  type="text"
                  v-model="form.link"
                  class="form-control shadow-sm"
                  id="link"
                  required
                />
              </div>

              <FileUploadPreview
                v-if="form.linkType === 'pdf'"
                label="Subir PDF"
                id="file"
                accept="application/pdf"
                :file="form.file"
                :preview="form.file ? form.file.name : ''"
                :required="!isEditing"
                @file-change="updateFile('file', $event)"
                no-file-message="No se ha seleccionado ningún archivo"
              />
            </div>

            <FileUploadPreview
              accept="image/*"
              label="Imagen"
              id="image"
              :file="form.image"
              :preview="form.imagePreview"
              :required="!isEditing"
              @file-change="updateFile('image', $event)"
              no-file-message="No se ha seleccionado ninguna imagen"
            />

            <div class="mb-3">
              <label for="order" class="form-label">Orden</label>
              <input
                type="number"
                v-model="form.order"
                class="form-control"
                id="order"
                required
              />
            </div>
            <button type="submit" class="btn btn-primary">
              {{ isEditing ? "Actualizar" : "Guardar" }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import { Modal } from "bootstrap";
import {
  createButtonLink,
  updateButtonLink,
} from "@/services/api/buttonLinkService.js";
import FileUploadPreview from "@/components/shared/FileUploadPreview.vue";
import userConfirmation from "@/utils/userConfirmation.js";

const BUTTON_LINK_MODAL_ID = "buttonLinkModal";

export default {
  components: {
    FileUploadPreview,
  },
  props: {
    view: {
      type: Object,
      required: true,
    },
    header: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const isEditing = ref(false);
    const form = ref({
      name: "",
      title: "",
      content: "",
      link: "",
      file: null,
      image: null,
      order: "",
      view_id: props.view.id,
      imagePreview: "",
      linkType: 'link',
      type: 1, // Valor inicial para 'type', 1 si no está activo, 2 si está activo
    });

    watch(
      () => props.header,
      (newHeader) => {
        if (newHeader) {
          isEditing.value = true;
          form.value = {
            name: newHeader.name,
            title: newHeader.title,
            content: newHeader.content,
            link: newHeader.link,
            image: null,
            file: newHeader.file || null,
            order: newHeader.order,
            view_id: newHeader.view.id,
            imagePreview: newHeader.image,
            linkType: newHeader.link ? 'link' : (newHeader.file ? 'pdf' : 'link'),
            type: newHeader.type || 1, // Si está activo, será 2, si no, será 1
          };
        } else {
          isEditing.value = false;
          form.value.imagePreview = "";
          form.value.linkType = 'link'; 
          form.value.type = 1; // Reiniciar a 1 si no está activo
        }
      },
      { immediate: true }
    );

    const updateFile = (type, file) => {
      form.value[type] = file;
    };

    const toggleButtonType = () => {
      form.value.type = form.value.type === 1 ? 2 : 1;
    };

    const submitForm = async () => {
      if (form.value.linkType === 'link') {
        form.value.file = null;
      } else if (form.value.linkType === 'pdf') {
        form.value.link = null;
      }

      const formData = new FormData();

      Object.keys(form.value).forEach((key) => {
        if (key !== "imagePreview" && (key !== "image" || form.value.image)) {
          formData.append(key, form.value[key]);
        }
      });
      const editOrCreate = isEditing.value ? "editar" : "crear";
      const confirmed = await userConfirmation(
        `¿Está seguro de ${editOrCreate} el button?`
      );
      if (!confirmed) return;

      try {
        if (isEditing.value) {
          await updateButtonLink(props.header.id, formData);
          emit("success", "Button actualizado exitosamente.");
        } else {
          await createButtonLink(formData);
          emit("success", "Button creado exitosamente.");
        }

        emit("buttonLink-created");
        closeForm();
      } catch (error) {
        emit(
          "error",
          error.response?.data?.message || "Error al procesar la solicitud."
        );
      }
    };

    const closeForm = () => {
      emit("close");
      const modalElement = document.getElementById(BUTTON_LINK_MODAL_ID);
      const modalInstance = Modal.getInstance(modalElement);
      modalInstance.hide();
    };

    onMounted(() => {
      const modalElement = document.getElementById(BUTTON_LINK_MODAL_ID);
      const modalInstance = new Modal(modalElement);
      modalInstance.show();
    });

    return {
      form,
      isEditing,
      updateFile,
      submitForm,
      closeForm,
      toggleButtonType,
    };
  },
};
</script>

<style scoped>
.modal-content {
  padding: 20px;
  background-color: #f9f9f9;
}
</style>
