import axios from '@/plugins/axios';

export const fetchPricesApi = async ({ page, perPage, search, type }) => {
  return axios.get("/prices", {
    params: {
      page: page,
      per_page: perPage,
      search: search,
      vehiculeType: type,
    },
    withCredentials: false,
  });
};