export const sidebarMenuItemsService = [
    {
      icon: "fas fa-home",
      link: "/",
      label: "Home",
    },
    {
      icon: "fas fa-dollar-sign",
      link: "/main/precios",
      label: "Precios",
      subMenu: [
        {
          icon: 'fas fa-car',
          label: 'Autos',
          link: '/main/precios/autos',
          value: 1,
        },
        {
          icon: 'fas fa-truck',
          label: 'Camiones',
          link: '/main/precios/camiones',
          value: 3,
        },
        {
          icon: 'fas fa-tractor',
          label: 'Maquinaria Agricola',
          link: '/main/precios/maquinaria',
          value: 4,
        },
        {
          icon: 'fas fa-motorcycle',
          label: 'Motos',
          link: '/main/precios/motos',
          value: 2,
        },
      ], 
    },
/*     {
      icon: "fa fa-address-card",
      link: "/main/novedades",
      label: "Novedades",
      type: 2,
    }, */
     {
      icon: "fas fa-chart-line",
      link: "/main/indices",
      label: "Indices de Precio",
      type: 1,
    }, 
    {
      icon: "fas fa-file-alt",
      link: "/main/vista",
      label: "Vista",
      type:1,
    },
    {
      icon: "fas fa-user-shield",
      link: "/main/administrador",
      label: "Administrador",
      subMenu: [
        {
          icon: 'fas fa-user', 
          label: 'Usuarios',
          link: '/main/administrador/usuarios',
          value: 1,
        },
        {
          icon: 'fas fa-user-friends', 
          label: 'Habitualistas',
          link: '/main/administrador/habitualist',
          value: 3,
        },
        {
          icon: 'fas fa-building',
          label: 'Concesionarios',
          link: '/main/administrador/concessionaires',
          value: 4,
        },        
      ], 
    },
  ];
  