<template>
  <div class="modal fade" id="sliderModal" tabindex="-1" aria-labelledby="sliderModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-custom-width">
      <div class="modal-content">
        <ModalHeader :view="view" subtitle="ButtonLink" modalId="sliderModal" @close="closeModal" />
        <div class="modal-body">
          <div class="d-flex justify-content-between mb-3">
            <SearchInput v-model="searchTerm" />
            <button class="btn btn-primary" @click="openButtonLinkForm">Nuevo</button>
          </div>
          <div v-if="loading" class="text-center">Cargando...</div>
          <div v-else>
            <div class="table-responsive" style="padding-bottom: 20px;">
              <table
                ref="table"
                class="table table-bordered table-striped table-responsive-stack"
                :id="BUTTON_LINK_MODAL_ID"
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Title</th>
                    <th>Content</th>
                    <th>Link</th>
                    <th>Image</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th>Estado</th>
                    <th>Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="buttonLink in filteredButtonLink" :key="buttonLink.id">
                    <td data-label="Name">{{ buttonLink.name }}</td>
                    <td data-label="Title">{{ buttonLink.title }}</td>
                    <td data-label="Content">{{ buttonLink.content }}</td>
                    <td data-label="Link">{{ buttonLink.link }}</td>
                    <td data-label="Image">
                      <template v-if="buttonLink.image.endsWith('.webm')">
                        <video class="img-thumbnail" style="max-width: 100px; max-height: 100px;" controls>
                          <source :src="buttonLink.image" type="video/webm">
                          Tu navegador no soporta el formato de video.
                        </video>
                      </template>
                      <template v-else>
                        <img :src="buttonLink.image" alt="Image" class="img-thumbnail" style="max-width: 100px; max-height: 100px;">
                      </template>
                    </td>
                    <td data-label="Created At">{{ buttonLink.created_at }}</td>
                    <td data-label="Updated At">{{ buttonLink.updated_at }}</td>
                    <td data-label="Estado">
                      <div class="custom-switch">
                        <input
                          type="checkbox"
                          :id="'switch-' + buttonLink.id"
                          v-model="buttonLink.status"
                          :true-value="2"
                          :false-value="1"
                          @change="() => updateButtonLinkStatusSwitch(buttonLink)"
                        />
                        <label :for="'switch-' + buttonLink.id"></label>
                      </div>
                    </td>
                    <td data-label="Opciones">
                      <button class="btn btn-sm btn-outline-primary" @click="editButtonLink(buttonLink)">Editar</button>
                      <button class="btn btn-sm btn-outline-danger" @click="removeButtonLink(buttonLink.id)">Eliminar</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <DataPagination
              :current-page="currentPage"
              :last-page="lastPage"
              :has-more-pages="hasMorePages"
              @fetch-views="loadButtonLink"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <ButtonLinkForm
    v-if="showButtonLinkForm"
    :view="view"
    :header="selectedButtonLink"
    @close="closeButtonLinkForm"
    @buttonLink-created="loadButtonLink"
    @success="handleSuccess"
    @error="handleError"
  />


</template>

<script>
import { reactive, ref, computed, onMounted } from 'vue';
import { Modal } from 'bootstrap';
import ModalHeader from '@/components/shared/ModalHeader.vue';
import DataPagination from '@/components/data-display/DataPagination.vue';
import SearchInput from '@/components/data-display/SearchInput.vue';
import userConfirmation from "@/utils/userConfirmation.js";
import { fetchButtonLinks, deleteButtonLink, updateButtonLinkStatus } from '@/services/api/buttonLinkService.js';
import { usePagination } from '@/composables/usePagination';
import { useNotification } from '@/utils/notificationUtils';
import { updateStatus } from '@/utils/elements/statusUpdater.js';
import ButtonLinkForm from './ButtonLinkForm.vue';

const BUTTON_LINK_MODAL_ID = 'sliderModal';

export default {
  components: {
    ModalHeader,
    DataPagination,
    SearchInput,
    ButtonLinkForm,
  },
  props: {
    view: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const buttonLinks = reactive([]);
    const loading = ref(false);
    const showButtonLinkForm = ref(false);
    const selectedButtonLink = ref(null);
    const searchTerm = ref('');

    const { currentPage, lastPage, hasMorePages } = usePagination();

    const { handleSuccess, handleError } = useNotification();

    const filteredButtonLink = computed(() => {
      if (!searchTerm.value) return buttonLinks.value;
      return buttonLinks.value.filter(buttonLink =>
        buttonLink.name.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
        buttonLink.title.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
        buttonLink.content.toLowerCase().includes(searchTerm.value.toLowerCase())
      );
    });

    const loadButtonLink = async (page = 1) => {
      loading.value = true;
      try {
        const response = await fetchButtonLinks(props.view.id, page);
        buttonLinks.value = response;
        currentPage.value = response.current_page || 1;
        lastPage.value = response.last_page || 1;
        hasMorePages.value = currentPage.value < lastPage.value;
      } catch (error) {
        console.error('Error fetching buttonLinks:', error);
      } finally {
        loading.value = false;
      }
    };

    const openButtonLinkForm = () => {
      selectedButtonLink.value = null;
      showButtonLinkForm.value = true;
    };

    const closeButtonLinkForm = () => {
      showButtonLinkForm.value = false;
    };

    const closeModal = () => {
      emit('close');
    };

    const editButtonLink = (buttonLink) => {
      selectedButtonLink.value = buttonLink;
      showButtonLinkForm.value = true;
    };

    const removeButtonLink = async (headerId) => {
      const confirmed = await userConfirmation();
      if (!confirmed) return;
      try {
        await deleteButtonLink(headerId);
        loadButtonLink(currentPage.value);
      } catch (error) {
        console.error('Error eliminando buttonLink:', error);
      }
    };

    const updateButtonLinkStatusSwitch = (buttonLink) => {
      updateStatus(buttonLink, updateButtonLinkStatus, 'buttonLink');
    };

    onMounted(async () => {
      loadButtonLink();
      const modalElement = document.getElementById(BUTTON_LINK_MODAL_ID);
      const modalInstance = new Modal(modalElement);
      modalInstance.show();
    });

    return {
      buttonLinks,
      loading,
      showButtonLinkForm,
      selectedButtonLink,
      searchTerm,
      currentPage,
      lastPage,
      hasMorePages,
      filteredButtonLink,
      loadButtonLink,
      removeButtonLink,
      openButtonLinkForm,
      closeButtonLinkForm,
      closeModal,
      editButtonLink,
      handleSuccess,
      handleError,
      updateButtonLinkStatusSwitch,
    };
  },
};
</script>

<style scoped>
.modal-content {
  padding: 20px;
  background-color: #f9f9f9;
}

.modal-custom-width {
  max-width: 85%;
}

.img-thumbnail {
  object-fit: cover;
}

.custom-switch {
  display: inline-block;
  width: 43px;
  height: 28px;
  position: relative;
}

.custom-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom-switch label {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.custom-switch label:before {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: white;
  left: 1px;
  bottom: 1px;
  transition: transform 0.3s;
}

.custom-switch input:checked + label {
  background-color: #4caf50;
}

.custom-switch input:checked + label:before {
  transform: translateX(20px);
}

.table-responsive-stack {
  width: 100%;
  table-layout: fixed;
}

.table-responsive-stack th,
.table-responsive-stack td {
  word-wrap: break-word;
}

@media screen and (max-width: 768px) {
  .table-responsive-stack tr {
    display: block;
    border-bottom: 3px solid #ccc;
  }

  .table-responsive-stack th {
    display: none;
  }

  .table-responsive-stack td {
    display: block;
    width: 100%;
    text-align: right;
    position: relative;
    padding-left: 50%;
    box-sizing: border-box;
  }

  .modal-dialog {
    margin: 0;
    width: 100%;
  }

  .modal-content {
    width: 100%;
  }

  .modal-custom-width {
    max-width: 100%;
  }
}

.table-responsive {
  padding-bottom: 20px;
}

tbody {
  width: 100%;
}
</style>
